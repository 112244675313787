import axios from "axios";

export const isDisposableEmail = async (value) =>
  await axios
    .get(`https://open.kickbox.com/v1/disposable/${value}`)
    .then((res) => res.data.disposable);

export const loadHubspotWidget = () => {
  const script = document.createElement("script");
  script.src = "//js.hs-scripts.com/5838327.js";
  script.async = true;
  script.type = "text/javascript";
  script.id = "hubspot-widget";
  
  if (!document.getElementById("hubspot-widget")) {
    document.head.appendChild(script);
  }
};
