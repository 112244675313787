import React, { useState, useContext, useRef, useEffect } from "react";
import {
  Columns,
  Button,
  Form,
  Notification,
} from "react-bulma-components/full";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress } from "react-google-places-autocomplete";
import "./EditorSiteForm.scss";
import _ from "lodash";
import SiteConstants from "../../constants/Site";
import CountryCodes from "../../assets/data/country_code";
import CurrencyCodes from "../../assets/data/currency_code";
import SiteService from "../../services/Site";
import Map from "../shared/Map";
import LocaleContext from "../../context/LocaleContext";
import { FolderSelect } from "../shared/FolderSelect";
import { SwitchButton } from "../SwitchButton";
import { currenciesToFilter } from "../../utils/Utils";
import styled from "styled-components";
import { CustomButton } from "../CustomButton";
import { CustomButtonSecondary } from "../CustomButtonSecondary";
import MapCreator from "../shared/MapCreator";
const Step1 = (props) => {
  const [mapError, setMapError] = useState("");
  const [mapValue, setMapValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [currentFolder, setCurrentFolder] = useState("");
  const mapRef = useRef();
  useEffect(() => {
    if (props.site.address) {
      setMapValue({ label: props.site.address, value: props.site.address });
    } else {
      setInputValue("");
    }
  }, [props.site.address]);

  useEffect(() => {
    if (props.site.folder) {
      setCurrentFolder(props.site.folder);
    }
  }, []);

  const sortCountries = (array) => {
    return function innerSort(a, b) {
      const varA =
        typeof array[a] === "string" ? array[a].toUpperCase() : array[a];
      const varB =
        typeof array[b] === "string" ? array[b].toUpperCase() : array[b];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return comparison;
    };
  };

  const OptimizeCheckbox = styled(Form.Checkbox)`
    background: #9dcf56;
    color: white;
    display: flex !important;
    align-items: center;
    justify-content: space-around;
    width: 30%;
    height: 2.25em;
    padding: 0 0.5em;
  `;

  const {
    site,
    name,
    country,
    currency,
    description,
    addressGeocode,
    handleCodeFromMarker,
    handleCodeFromGoogle,
    canGoToStepTwo,
    handleNextStep,
    handleChangeInput,
    handleSubmitProducerSite,
    editMode,
    isSubmitingSite,
    isFetchingTimeZone,
    timeZoneError,
    cancelEditor,
    isCollectiveSiteEditorMode = false,
    handleSubmitSite,
    handleRemoveEditor,
    co2rate,
    autoCo2,
  } = props;
  const { Translation, i18n } = useContext(LocaleContext);
  let folders;
  try {
    folders = JSON.parse(localStorage.getItem("userData")).folders;
  } catch (e) {
    console.log("No folders");
  }
  const handleFolderSelect = ({ target: { value } }) => {
    const folder = value === "ALL" ? "" : value;
    setCurrentFolder(value);
    handleChangeInput({ target: { name: "folder", value: folder } });
  };

  return (
    <div className="create-site-wrapper container" style={{ padding: 0 }}>
      <div className="title is-4 has-text-centered">
        <div className="subtitle is-uppercase">
          {site.isFullSellOut ? (
            <Translation>
              {(t) => `${t("step")} 1 ${t("outOfPreposition")} 2`}
            </Translation>
          ) : (
            <Translation>
              {(t) => `${t("step")} 1 ${t("outOfPreposition")} 3`}
            </Translation>
          )}
        </div>
        <Translation>{(t) => t("projectInfo")}</Translation>
      </div>
      <div style={{ display: "flex", gap: "6%", justifyContent: "center" }}>
        <div style={{ width: "50%" }}>
          <Form.Field>
            <Form.Control>
              <Form.Label htmlFor="name">
                {" "}
                <Translation>{(t) => t("name")}</Translation>*
              </Form.Label>
              <Form.Input
                placeholder={i18n.t("projectName")}
                value={name}
                name="name"
                onChange={handleChangeInput}
              />
            </Form.Control>
          </Form.Field>
          {!isCollectiveSiteEditorMode && (
            <div>
              <Form.Field>
                <Form.Control>
                  <Form.Label htmlFor="country">
                    {" "}
                    <Translation>{(t) => t("country")}</Translation>*
                  </Form.Label>
                  <Form.Select
                    name="country"
                    onChange={handleChangeInput}
                    value={country}
                    style={{ width: "100%" }}
                  >
                    <option>
                      {CountryCodes[country] || "Choisissez un pays"}
                    </option>
                    {_.keys(CountryCodes)
                      .sort(sortCountries(CountryCodes))

                      .map((country) => (
                        <option
                          key={CountryCodes[country]}
                          value={country.toLowerCase()}
                        >
                          {CountryCodes[country]}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Control>
              </Form.Field>

              <Form.Field>
                <Form.Control>
                  <Form.Label htmlFor="address">
                    {" "}
                    <Translation>{(t) => t("address")}</Translation>*
                  </Form.Label>
                  <GooglePlacesAutocomplete
                    // apiKey={KEYS.MAPS}
                    selectProps={{
                      placeholder: `${i18n.t("address")} ${i18n.t(
                        "or",
                      )} ${i18n.t("geocode")}`,
                      styles: {
                        dropdownIndicator: (base) => ({
                          ...base,
                          color: "#3273dc",
                          "&:hover": {
                            color: "#3273dc",
                          },
                        }),
                        placeholder: () => ({
                          color: "#CEC2C2",
                        }),
                      },
                      value: mapValue,
                      blurInputOnSelect: false,
                      autoFocus: true,
                      backspaceRemovesValue: true,
                      onInputChange: (input) => {
                        setInputValue(input);
                        // Ajout regex pour vérifier si l'entrée correspond à des coordonnées GPS
                        const coordsRegex = /^(-?\d+\.?\d*),\s*(-?\d+\.?\d*)$/;
                        const match = input.match(coordsRegex);

                        if (match) {
                          const [, lat, lng] = match;
                          const latitude = parseFloat(lat);
                          const longitude = parseFloat(lng);

                          // Check si les coordonnées sont valides
                          if (
                            latitude >= -90 &&
                            latitude <= 90 &&
                            longitude >= -180 &&
                            longitude <= 180
                          ) {
                            // inversion de la géolocalisation
                            const geocoder = new window.google.maps.Geocoder();
                            geocoder.geocode(
                              { location: { lat: latitude, lng: longitude } },
                              (results, status) => {
                                if (status === "OK" && results[0]) {
                                  const address = results[0];
                                  const formattedResult = {
                                    label: address.formatted_address,
                                    value: {
                                      description: address.formatted_address,
                                      place_id: address.place_id,
                                      structured_formatting: {
                                        main_text: address.formatted_address,
                                        secondary_text: "",
                                      },
                                    },
                                  };
                                  setInputValue(address.formatted_address);
                                  //mettre à jour la valeur de la carte
                                  geocodeByAddress(formattedResult.label)
                                    .then((res) => {
                                      setMapValue(formattedResult);
                                      setMapError("");
                                      handleCodeFromGoogle(res[0]).catch(() => {
                                        setMapError(
                                          "Veuillez sélectionner votre département et votre commune dans la liste suivante",
                                        );
                                      });
                                    })
                                    .catch((err) =>
                                      console.log("ERREUR CARTE => ", err),
                                    );
                                }
                              },
                            );
                          }
                        }
                      },
                      onChange: (e) => {
                        geocodeByAddress(e.label)
                          .then((res) => {
                            console.log({ res });
                            setMapValue({ label: e.label, value: e.value });
                            setMapError("");
                            handleCodeFromGoogle(res[0]).catch(() => {
                              setMapError(
                                "Veuillez sélectionner votre département et votre commune dans la liste suivante",
                              );
                            });
                          })
                          .catch((err) => console.log("ERREUR CARTE => ", err));
                      },
                    }}
                    autocompletionRequest={{
                      componentRestrictions: {
                        country:
                          country === "fr"
                            ? ["fr", "re", "gp", "mq", "gf"]
                            : country,
                      },
                    }}
                    inputClassName="input"
                  />
                </Form.Control>
              </Form.Field>

              {site.isFullSellOut && (
                <Form.Field>
                  <SwitchButton
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      width: "50%",
                      marginLeft: "0",
                    }}
                    isChecked={site.includeTVA}
                    onChange={(e) =>
                      handleChangeInput({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                    name={"includeTVA"}
                    text={i18n.t("includeTVA")}
                  />
                </Form.Field>
              )}
              <Form.Field>
                <Form.Control>
                  <Form.Label htmlFor="description">
                    {" "}
                    <Translation>{(t) => t("description")}</Translation>
                  </Form.Label>
                  <Form.Textarea
                    placeholder={i18n.t("description")}
                    name="description"
                    value={description}
                    onChange={handleChangeInput}
                    required
                    rows="2"
                    style={{ resize: "none" }}
                  />
                </Form.Control>
              </Form.Field>
              <Form.Field>
                <Form.Control>
                  <Form.Label htmlFor="currency">
                    {" "}
                    <Translation>{(t) => t("currency.label")}</Translation>*
                  </Form.Label>
                  <Form.Select
                    name="currency"
                    onChange={handleChangeInput}
                    value={currency || "EUR"}
                    style={{ width: "100%" }}
                  >
                    {/* <option>
                      {CurrencyCodes[currency] || "Choisissez une devise"}
                    </option> */}
                    {_.keys(CurrencyCodes)
                      .sort(sortCountries(CurrencyCodes))
                      .filter((currency) =>
                        currenciesToFilter.includes(currency),
                      )
                      .map((currency) => (
                        <option key={CurrencyCodes[currency]} value={currency}>
                          {CurrencyCodes[currency]}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Control>
              </Form.Field>
              <Form.Field>
                <Form.Control>
                  <Form.Label htmlFor="carbon">
                    {" "}
                    <Translation>{(t) => t("cO2")}</Translation>*
                  </Form.Label>
                  <div style={{ display: "flex", gap: "10px" }}>
                    {country === "fr" && (
                      <OptimizeCheckbox
                        checked={autoCo2}
                        style={{ width: "40%" }}
                        name="autoCo2"
                        onChange={props.handleChangeCheckbox}
                      >
                        {i18n.t("cO2Base")}
                      </OptimizeCheckbox>
                    )}

                    <Form.Input
                      placeholder={i18n.t("cO2")}
                      style={{ width: "60%" }}
                      value={co2rate}
                      name="co2rate"
                      type="number"
                      onChange={handleChangeInput}
                      disabled={autoCo2}
                    />
                  </div>
                </Form.Control>
              </Form.Field>
              {!isCollectiveSiteEditorMode && (
                <Form.Control>
                  <Form.Label htmlFor="folders">
                    <Translation>
                      {(t) => t("listOfProjects.allFolders")}
                    </Translation>
                    *
                  </Form.Label>
                  <Form.Field>
                    <div>
                      <Form.Select
                        style={{
                          width: "100%",
                          height: "2.25em",
                          borderRadius: "3px",
                        }}
                        className="th-filter"
                        value={currentFolder}
                        onChange={handleFolderSelect}
                        id="folders"
                      >
                        <option value="ALL">Non renseigné</option>
                        {folders?.map((folder) => (
                          <option key={folder.id} value={folder.id}>
                            {folder.displayName}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </Form.Field>
                </Form.Control>
              )}
            </div>
          )}
        </div>

        {!isCollectiveSiteEditorMode &&
          (addressGeocode.length !== 0 ? (
            <div style={{ margin: "auto", width: "50%" }}>
              <p className="map-info">
                <Translation>{(t) => t("movePin")}</Translation>
              </p>
              <MapCreator
                coordinates={addressGeocode}
                getCodeFromMarker={handleCodeFromMarker}
                addPdfURL={SiteService.addPdfElements}
                siteId={props.site.id}
                ref={mapRef}
                size={"400px"}
              />
            </div>
          ) : (
            ""
          ))}
      </div>

      {!isCollectiveSiteEditorMode && timeZoneError && (
        <Notification color="danger" style={{marginTop: "5%"}}>
          Erreur lors de la récupération du fuseau horaire : {timeZoneError}
        </Notification>
      )}

      {[SiteConstants.type.CONSUMER, SiteConstants.type.CONSUMER_PLUS].includes(
        site.type,
      ) &&
        !site.isFullSellOut && (
          <div
            className="has-text-centered"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5%",
              marginBottom: "1%",
            }}
          >
            {editMode ? (
              <CustomButtonSecondary
                onClick={() => cancelEditor(site)}
                loading={isFetchingTimeZone}
              >
                {" "}
                <Translation>{(t) => `${t("cancel")}`}</Translation>
              </CustomButtonSecondary>
            ) : (
              <CustomButtonSecondary
                onClick={handleRemoveEditor}
                // loading={isFetchingTimeZone}
                iconPosition="left"
                icon={<i className="fa fa-angle-left" />}
              >
                <Translation>{(t) => `${t("previous")}`}</Translation>
              </CustomButtonSecondary>
            )}

            <CustomButton
              disabled={!canGoToStepTwo()}
              onClick={handleNextStep}
              iconPosition="right"
              icon={<i className="fa fa-angle-right" />}
            >
              <Translation>{(t) => t("next")}</Translation>
            </CustomButton>
          </div>
        )}
      {site.isFullSellOut && (
        <div
          className="has-text-centered"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5%",
            marginBottom: "2%",
          }}
        >
          {editMode ? (
            <CustomButtonSecondary
              onClick={() => cancelEditor(site)}
              loading={isFetchingTimeZone}
            >
              {" "}
              <Translation>{(t) => `${t("cancel")}`}</Translation>
            </CustomButtonSecondary>
          ) : (
            <CustomButtonSecondary
              onClick={handleRemoveEditor}
              loading={isFetchingTimeZone}
            >
              <i className="fa fa-angle-left" style={{ marginRight: "10px" }} />{" "}
              <Translation>{(t) => `${t("previous")}`}</Translation>
            </CustomButtonSecondary>
          )}

          <CustomButton
            onClick={
              site.isFullSellOut ? handleSubmitSite : handleSubmitProducerSite
            }
            loading={isSubmitingSite || isFetchingTimeZone}
            disabled={!canGoToStepTwo() || isSubmitingSite}
          >
            <Translation>
              {(t) =>
                editMode === true ? t("saveProject") : t("createProject")
              }
            </Translation>
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default Step1;
