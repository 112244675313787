import styled, { keyframes } from "styled-components";

import { Columns, Loader, Form, Button } from "react-bulma-components/full";

const Square = styled.div`
  width: 50vw;
  height: 85vh;
  background-color: ${(props) => props.theme.main};
  position: relative;
  font-size: 180%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  border-radius: 15px;
  @media screen and (max-width: 720px) {
    width: 100vw !important;
    height: 80vh !important;
    font-size: 70% !important;
    white-space: wrap;
    text-align: center;
  }
`;

const StyledStepContainer = styled.div`
  height: 99vh;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(1000px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const StyledStep1 = styled(Columns)`
  animation: ${fadeIn} 0.4s ease-in-out forwards;
  background-color: ${(props) => props.theme.main};
  padding: 1em;
  width: 70%;
  border-radius: 15px;
  background-color: ${(props) => props.theme.main};

  .column {
    .field {
      .control {
        .google-places-autocomplete {
          input {
            font-size: 1.5rem;

            background-color: ${(props) => props.theme.main};
            color: ${(props) => props.theme.mainText};
            ::placeholder {
              color: ${(props) => props.theme.mainText};
            }
            border-radius: 0;
            border: none;
            border-bottom: 3px solid;
            box-shadow: none;
            border-color: ${(props) => props.theme.mainText};
            &:active,
            &:focus {
              border-radius: 0;
              border: none;
              border-bottom: 3px solid;
              box-shadow: none;
              border-color: ${(props) => props.theme.second};
            }
          }
        }
      }
    }
  }
`;

const Arrow = styled(Button)`
  position: absolute;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  cursor: pointer;
  color: white;
  font-size: 3rem;
  background-color: ${(props) =>
    props.step0 ? props.theme.second : props.theme.main};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  line-height: 0;
  padding: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => props.theme.third};
    color: ${(props) => props.theme.secondText || "white"};
    border: none;
  }

  &:active {
    transform: translateY(-50%) scale(0.95); /* Conserver l'alignement lors de l'interaction */
  }

  &.left {
    left: 30px;
    border: none;
  }

  &.start {
    width: auto;
    padding: 30px;
    font-size: 1.8rem;
    right: 1% !important;
    transform: translateY(-50%);
  }

  &.right {
    right: 30px;
    border: none;
  }
  &.is-loading {
    &.is-info {
      &:after {
        color: ${(props) => props.theme.main};
        border-color: transparent transparent ${(props) => props.theme.main}
          ${(props) => props.theme.main} !important;
      }
    }
  }
`;

const ArrowIcon = styled.i`
  &.right {
    padding-left: 6px;
    color: white;
  }
  &.left {
    padding-right: 6px;
    color: white;
  }
  padding-top: 3px;
  &:hover {
    color: ${(props) => props.theme.secondText || "white"};
  }
`;

const StyledLoader = styled(Loader)`
  width: ${(props) => props.width || "auto"};
  margin: ${(props) => props.margin || "auto"};
  margin-top: ${(props) => props.marginTop || "auto"};
  height: ${(props) => props.height || "auto"};
  border: ${(props) => `4px solid ${props.theme.second || "#9EBE5A"}`};
  border-top-color: transparent;
  border-right-color: transparent;
`;

const StyledFormInput = styled(Form.Input)`
  font-size: 1.2rem;

  background-color: ${(props) => props.theme.main};
  color: ${(props) => props.theme.mainText};
  ::placeholder {
    color: ${(props) => props.theme.mainText};
  }
  border-radius: 0;
  border: none;
  border-bottom: 3px solid;
  box-shadow: none;
  border-color: ${(props) => props.theme.mainText};
  &:active,
  &:focus {
    border-radius: 0;
    border: none;
    border-bottom: 3px solid;
    box-shadow: none;
    border-color: ${(props) => props.theme.second};
  }
  text-align: ${(props) => props.align || "left"};
`;

const StyledGaugeDescription = styled.article`
  line-height: 1em;
  font-size: 100%;
  ${(props) => props.big && `font-size:150%;`}
  ${(props) => props.centered && `text-align:center;`}
`;

export {
  Square,
  Arrow,
  ArrowIcon,
  StyledLoader,
  StyledFormInput,
  StyledGaugeDescription,
  StyledStepContainer,
  StyledStep1,
};
